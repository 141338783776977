let main = {
  // 移动端导航
  mobileNav() {
    let mobileExpandShow = false
    let $toggle = $('.toggle')
    let $expand = $('.expand')
    let $header = $('.header')
    $toggle.on('click', function() {
      if(mobileExpandShow) {
        $expand.fadeOut(200)
        $(this).removeClass('is-toggle')
        $header.removeClass('is-fixed')
      } else {
        $expand.fadeIn(200)
        $(this).addClass('is-toggle')
        $header.addClass('is-fixed')
      }
      mobileExpandShow = !mobileExpandShow
    })
  },
  // 图片滚动
  indexSwiper() {
    let $indexSwiper = $('.index-swiper')
    if($indexSwiper.is(':hidden') || $indexSwiper.length === 0) return
    new Swiper('.swiper-container', {
      autoplay: true,
      loop: true,
      pagination: {
        clickable :true,
        el: '.swiper-pagination'
      }
    })
  },
  // 图片滚动
  swiper() {
    let $mobileList = $('.about-mobileList')
    if($mobileList.is(':hidden') || $mobileList.length === 0) return
    new Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
      }
    })
  },
  // 设置当前年份
  setCurrentYear() {
    let year = (new Date()).getFullYear()
    $('.current-year').html(year)
  },
  init() {
    // 解决移动端active无效
    document.body.addEventListener('touchstart', function (){})

    this.mobileNav()
    this.indexSwiper()
    this.swiper()
    this.setCurrentYear()
  }
}
main.init()
